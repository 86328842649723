module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-133163351-1"},
    },{
      plugin: require('/opt/build/repo/node_modules/@wapps/gatsby-plugin-material-ui/gatsby-browser'),
      options: {"plugins":[],"theme":{"palette":{"type":"dark","primary":{"light":"#444","main":"#eee","dark":"#eee","contrastText":"#000"}},"shape":{"borderRadius":0},"overrides":{"MuiChip":{"root":{"borderRadius":0}}},"typography":{"useNextVariants":true,"fontFamily":"Montserrat,-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"","body2":{"fontSize":"1em"}}}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Cutive+Mono","Montserrat:300,400,500"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://internetario.ga"},
    }]
